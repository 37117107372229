/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* show notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState, Suspense } from "react";
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NICE_BANK_CODE } from "../../lib/global_variable"

// control session
import Session from "module/session/Session";
// Budget class 생성
import Budget from "module/budget/Budget";
// Funding class 생성
import Funding from "module/funding/Funding";
// User class 생성
import User from "module/user/User";

import Paginations from "../../components/Pagination";

// reactstrap components
import {
    Badge,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    PaginationItem,
    PaginationLink,
    PaginationComponent,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip
} from "reactstrap";

function BudgetList() {

    const [list, setList] = useState([]);
    const [searchType, setSearchType] = useState('');
    const [searchStatus, setSearchStatus] = useState('');
    const [searchOrder, setSearchOrder] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [reload, setReload] = useState(false);
    const sessionInfo = Session.getSession();
    const [budgetListCnt, setBudgetListCnt] = useState(0); //all budget list
    var pageSize = 100;

    //이미지 확장자 여부 확인 
    var reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;

    const [page, setPage] = useState(1);
    const [startPage, setStartPage] = useState(1);
    const [endPage, setEndPage] = useState(1);
    const offset = (page - 1) * pageSize; // 시작점과 끝점을 구하는 offset

    const state = {
        "O1": "신청",
        "CD": "취소",
        "HD": "승인보류",
        "OD": "승인완료",
    };


    let budget = new Budget(process.env.REACT_APP_PLATFORMAPI, {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization_Access': sessionInfo.token
    });


    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
    const second = String(now.getSeconds()).padStart(2, '0');
    // const formattedDateTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    const formattedDateTime = now.toISOString().replace('Z', '');


    useEffect(() => {
        loadList(page);
        setPagination();
    }, [])

    useEffect(() => {
        loadList(page);
    }, [page])

    useEffect(() => {
        setPagination();
    }, [list])

    const loadList = async (page) => {
        var param = '?pageSize=' + pageSize + '&currentPage=' + (page - 1);

        if (searchType != '' && searchKeyword != '') {
            switch (searchType) {
                case "nickname": param += '&nickname=' + searchKeyword; break;
                case "email": param += '&email=' + searchKeyword; break;
            }
        }

        if (searchStatus != '') {
            param += '&status=' + searchStatus;
        }

        if (searchOrder != '') {
            param += '&order=' + searchOrder;
        }

        var result = await budget.findAll(param);

        setPage(page);

        if (result.data.data && result.data.data.length > 0) {
            var budgetDatas = result.data.data;

            setList(budgetDatas);
            setBudgetListCnt(result.data.extraData.totalCount);

            setPagination();
        } else {
            setList([]);
            setBudgetListCnt(result.data.extraData.totalCount);
        }
    }

    const setPagination = () => {

        setStartPage(page);
        setEndPage((budgetListCnt % pageSize > 0 ? parseInt(budgetListCnt / pageSize) + 1 : parseInt(budgetListCnt / pageSize)));

        if (page == 1) {
            setStartPage(1);
        }
    }

    //상태값 업데이트 하기 
    const updateState = async () => {

        var updateList = document.querySelectorAll(".requestId")

        console.log(updateList);

        if (updateList.length < 1) {
            alert('상태값을 수정할 항목을 체크해주세요.');
            return false;
        } else {

            updateList.forEach(async element => {
                if (element.checked && document.getElementById('changeState').value != '') {
                    var created_at = document.getElementById('createdAt_' + element.value).value;

                    if (created_at.length == 22) {
                        created_at = created_at + '1';
                    }

                    var result = await budget.update('?requestId=' + element.value, {
                        requestId: element.value,
                        status: document.getElementById('changeState').value
                    })

                    console.log(result);
                }
            });

            alert('상태값을 변경하였습니다')
            window.location.reload();

        }

    }

    // 체크 리스트 삭제 처리 
    const deleteList = async () => {

        var deleteList = document.querySelectorAll(".requestId")

        console.log(deleteList);

        if (deleteList.length < 1) {
            alert('삭제할 항목을 체크해주세요.');
            return false;
        } else {

            deleteList.forEach(async element => {
                if (element.checked) {
                    var result = await budget.update(
                        {
                            requestId: element.value,
                            deletedAt: formattedDateTime
                        }
                    )
                    console.log(result);
                }
            });

            alert('리스트를 삭제하였습니다.')
            window.location.reload();

        }
    }

    // 정렬기능추가 
    const setOrderType = async (e) => {
        if (searchOrder == '') {
            setSearchOrder('asc');
        } else {
            setSearchOrder('');
        }

        loadList(page);
    }

    const handleChange = async (e) => {
        if (e.target.name == 'searchStatus') {
            setSearchStatus(e.target.value)
        } else if (e.target.name == 'searchType') {
            setSearchType(e.target.value)
        } else if (e.target.name == 'searchKeyword') {
            setSearchKeyword(e.target.value)
        }
    };

    return (
        <>
            <div className="content">
                <Row>
                    <Col lg="12" md="12">
                        <Card className="card-tasks">
                            <CardHeader>
                                <div className="d-inline-flex">
                                    <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
                                        <Label check>
                                            <Input type="select" value={searchStatus} name="searchStatus" id="searchStatus" onChange={handleChange}>
                                                <option value="">신청상태</option>
                                                <option value="O1">신청</option>
                                                <option value="CD">취소</option>
                                                <option value="HD">승인보류</option>
                                                <option value="OD">승인완료</option>
                                            </Input>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <Input type="select" name="searchType" id="searchType" value={searchType} onChange={handleChange}>
                                                    <option value="">검색타입</option>
                                                    <option value="email">이메일</option>
                                                    <option value="nickname">닉네임</option>
                                                </Input>
                                            </div>
                                            <input type="text" id="searchKeyword" name="searchKeyword" value={searchKeyword} class="form-control" aria-label="검색어를 입력해주세요." onChange={handleChange}></input>
                                            <Button className="btn btn-primary" onClick={() => { loadList(page) }}>검색하기</Button>
                                        </div>
                                    </FormGroup>
                                </div>
                                {/* <Link to='/admin/budgetCreate'>
                                    <Button style={{ float: "right" }}>등록</Button>
                                </Link> */}
                                <Button className="btn btn-danger" style={{ float: "right" }} onClick={deleteList}>삭제하기</Button>
                            </CardHeader>
                            <CardBody>
                                <div className="table-full-width table-responsive" style={{ overflowY: "scroll", overflowX: 'hidden' }}>
                                    <Table>
                                        <colgroup>
                                            <col width="5%" />
                                            <col width="7%" />
                                            <col width="7%" />
                                            <col width="10%" />
                                            <col width="10%" />
                                            <col width="10%" />
                                            <col width="10%" />
                                            <col width="10%" />
                                            <col width="10%" />
                                        </colgroup>
                                        <thead>
                                            <th></th>
                                            <th>이메일</th>
                                            <th>닉네임</th>
                                            <th>신청상태</th>
                                            <th>신청금액</th>
                                            <th>계좌번호</th>
                                            <th>은행명</th>
                                            <th>예금주</th>
                                            <th>신청일시<i class="fa-solid fa-sort ml-2" onClick={setOrderType}></i></th>
                                            <th>메모</th>
                                        </thead>
                                        <tbody>
                                            {list && list.map((budgetData, ind) => {
                                                return <tr>
                                                    <td className="w-5">
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input defaultValue={budgetData.referralRequest.requestId} type="checkbox" className="requestId" />
                                                                <span className="form-check-sign">
                                                                    <span className="check" />
                                                                </span>
                                                            </Label>
                                                        </FormGroup>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {budgetData.user ? budgetData.user.email : ''}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {budgetData.user ? budgetData.user.nickName : ''}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <a href={`/admin/budgetView?requestId=` + budgetData.referralRequest.requestId} style={{ cursor: "pointer" }}>
                                                            <span className="fs-5" style={{ display: 'inline-block' }}>
                                                                {state[budgetData.referralRequest.status]}
                                                            </span>
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a href={`/admin/budgetView?requestId=` + budgetData.referralRequest.requestId} style={{ cursor: "pointer" }}>
                                                            <span className="fs-5" style={{ display: 'inline-block' }}>
                                                                {budgetData.referralRequest.requestAmount} 원
                                                            </span>
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {(budgetData.user && budgetData.user.bankName) &&
                                                                NICE_BANK_CODE.map((option, index) => {
                                                                    if (option.value === budgetData.user.bankName) {
                                                                        return <p>{option.label}</p>
                                                                    }
                                                                })}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {budgetData.user ? budgetData.user.bankAccount : ''}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {budgetData.user ? budgetData.user.accountHolderName : ''}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {budgetData.referralRequest.createdAt}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {budgetData.referralRequest.memo}
                                                        </span>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <div className="pagination" id="pagination" style={{
                        position: "absolute",
                        left: "50%",
                        bottom: "0%",
                        tranform: "translate(-50%,0)"
                    }}>
                        <Paginations limit={pageSize} page={page} totalPosts={budgetListCnt} setPage={setPage} />
                    </div>
                </Row>
                <span>
                    선택된 신청상태
                    <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
                        <Label check>
                            <Input type="select" name="changeState" id="changeState" onChange={updateState}>
                                <option value="">신청상태</option>
                                <option value="O1">신청</option>
                                <option value="CD">취소</option>
                                <option value="HD">승인보류</option>
                                <option value="OD">승인완료</option>
                            </Input>
                        </Label>
                    </FormGroup>
                    으로 변경
                </span>
            </div>
        </>
    );
}


export default BudgetList;
