/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Icons from "views/Icons.js";
import Map from "views/Map.js";
import Notifications from "views/Notifications.js";
import Rtl from "views/Rtl.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import UserProfile from "views/UserProfile.js";

import SiteInfo from "views/SiteInfo.js";

// 공지사항
import NotiList from 'views/notice/NotiList'
import NotiCreate from 'views/notice/NotiCreate'

// 이벤트
import EventList from 'views/event/EventList'
import EventCreate from 'views/event/EventCreate'

// 로그인
import Login from 'views/login/Login'

// FAQ
import FaqList from 'views/faq/FaqList'
import FaqCreate from 'views/faq/FaqCreate'

// 약관정보
import AgreementList from 'views/agreement/AgreementList'
import AgreementCreate from 'views/agreement/AgreementCreate'

// 프로젝트
import ProjectList from 'views/project/ProjectList'
import ProjectCreate from './views/project/projectCreate'

// 투표 리스트
import VoteList from 'views/vote/VoteList'

// 관리자 등록
import ManagerCreate from "./views/manager/ManagerCreate";

// 투표결과 
import PollingResultList from 'views/pollingResult/pollingResultList';
import PollingResultCreate from 'views/pollingResult/pollingResultCreate';
// 펀딩 
import FundingList from 'views/funding/fundingList';
import FundingCreate from 'views/funding/fundingCreate';

// 구매내역 
import PayCreate from 'views/pay/PayCreate';
import PayList from 'views/pay/PayList';
import PayView from 'views/pay/PayView';

// 환불내역 
import RefundList from 'views/refund/RefundList';
import RefundView from 'views/refund/RefundView';

// 티켓내역 
import TicketList from 'views/ticket/TicketList';
import TicketCreate from 'views/ticket/TicketCreate';

// 출금내역 
import WithdrawCreate from 'views/withdraw/WithdrawCreate';
import WithdrawList from 'views/withdraw/WithdrawList';
import WithdrawView from 'views/withdraw/WithdrawView';



// dao 관련 라우트 추가 

// dao 프로젝트 내역 
import DaoProjectList from 'views/dao/project/ProjectList';
import DaoProjectCreate from 'views/dao/project/projectCreate';

// dao 프로젝트 공지사항 내역 
import DaoProjectNoticeList from 'views/dao/project/NotiList';
import DaoProjectNoticeCreate from 'views/dao/project/NotiCreate';

// dao 공지사항 내역 
import DaoNoticeList from 'views/dao/notice/NotiList';
import DaoNoticeCreate from 'views/dao/notice/NotiCreate';

// 수익 플랫폼 등록,리스트,상세
import PlatformCreate from "views/platform/PlatformCreate";
import PlatformList from "views/platform/PlatformList";
import PlatformView from "views/platform/PlatformView";

// 월별 수익 플랫폼 등록,리스트,상세
import BudgetCreate from "views/budget/BudgetCreate";
import BudgetList from "views/budget/BudgetList";
import BudgetView from "views/budget/BudgetView";

var routes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        rtlName: "لوحة القيادة",

        icon: "tim-icons icon-chart-pie-36",
        component: Dashboard,
        layout: "/admin"
    },
    {
        path: "/manager",
        name: "관리자 정보 관리",
        rtlName: "administrator",
        icon: "tim-icons icon-world",
        component: ManagerCreate,
        layout: "/admin"
    },
    // notice router - connect with notice list page
    {
        path: "/notice",
        name: "공지사항",
        rtlName: "notice",
        icon: "tim-icons icon-world",
        component: NotiList,
        layout: "/admin"
    },
    // notice router - connect with notice create page
    {
        path: "/noticeCreate",
        name: "공지사항 등록",
        rtlName: "create notice",
        icon: "tim-icons icon-world",
        component: NotiCreate,
        layout: "/admin"
    },
    // event router - connect with event list page
    {
        path: "/event",
        name: "이벤트",
        rtlName: "event",
        icon: "tim-icons icon-world",
        component: EventList,
        layout: "/admin"
    },
    // event router - connect with event create page
    {
        path: "/eventCreate",
        name: "이벤트 등록",
        rtlName: "create event",
        icon: "tim-icons icon-world",
        component: EventCreate,
        layout: "/admin"
    },
    // faq router - connect with faq list page
    {
        path: "/faq",
        name: "FAQ",
        rtlName: "FAQ",
        icon: "tim-icons icon-world",
        component: FaqList,
        layout: "/admin"
    },
    // faq router - connect with faq create page
    {
        path: "/faqCreate",
        name: "FAQ 등록",
        rtlName: "create FAQ",
        icon: "tim-icons icon-world",
        component: FaqCreate,
        layout: "/admin"
    },
    // agreement router - connect with agreement list page
    {
        path: "/agreement",
        name: "사이트 약관",
        rtlName: "사이트 약관",
        icon: "tim-icons icon-world",
        component: AgreementList,
        layout: "/admin"
    },
    // agreement router - connect with agreement create page
    {
        path: "/agreementCreate",
        name: "사이트 약관 등록",
        rtlName: "create",
        icon: "tim-icons icon-world",
        component: AgreementCreate,
        layout: "/admin"
    },
    // login router - connect with login page
    {
        path: "/login",
        name: "로그인",
        rtlName: "로그인",
        icon: "tim-icons icon-world",
        component: Login,
        layout: "/admin"
    },
    // project router - connect with project list page
    {
        path: "/project",
        name: "프로젝트",
        rtlName: "프로젝트",
        icon: "tim-icons icon-world",
        component: ProjectList,
        layout: "/admin"
    },
    // project router - connect with project create page
    {
        path: "/projectCreate",
        name: "프로젝트 등록",
        rtlName: "create project",
        icon: "tim-icons icon-world",
        component: ProjectCreate,
        layout: "/admin"
    },
    // polling router - connect with polling list page
    {
        path: "/vote",
        name: "투표",
        rtlName: "투표",
        icon: "tim-icons icon-world",
        component: VoteList,
        layout: "/admin"
    },
    // pollingResult router - connect with pollingResult list page
    {
        path: "/pollingResult",
        name: "투표결과",
        rtlName: "투표결과",
        icon: "tim-icons icon-world",
        component: PollingResultList,
        layout: "/admin"
    },
    // pollingResult router - connect with pollingResult create page
     {
        path: "/pollingResultCreate",
        name: "투표결과 등록",
        rtlName: "create funding",
        icon: "tim-icons icon-world",
        component: PollingResultCreate,
        layout: "/admin"
    },
    // funding router - connect with funding list page
    {
        path: "/funding",
        name: "펀딩",
        rtlName: "펀딩",
        icon: "tim-icons icon-world",
        component: FundingList,
        layout: "/admin"
    },
    // funding router - connect with funding create page
     {
        path: "/fundingCreate",
        name: "펀딩 등록",
        rtlName: "create funding",
        icon: "tim-icons icon-world",
        component: FundingCreate,
        layout: "/admin"
    },
    // pay router - connect with pay create page
    {
        path: "/payCreate",
        name: "구매내역 등록",
        rtlName: "구매내역 등록",
        icon: "tim-icons icon-world",
        component: PayCreate,
        layout: "/admin"
    },
    // pay router - connect with pay list page
    {
        path: "/payList",
        name: "구매내역",
        rtlName: "구매내역",
        icon: "tim-icons icon-world",
        component: PayList,
        layout: "/admin"
    },
    // pay router - connect with pay create page
     {
        path: "/payView",
        name: "구매상세",
        rtlName: "구매상세",
        icon: "tim-icons icon-world",
        component: PayView,
        layout: "/admin"
    }
    ,
    // refund router - connect with funding list page
    {
        path: "/refundList",
        name: "환불내역",
        rtlName: "환불내역",
        icon: "tim-icons icon-world",
        component: RefundList,
        layout: "/admin"
    },
    // refund router - connect with funding create page
     {
        path: "/RefundView",
        name: "환불상세",
        rtlName: "create funding",
        icon: "tim-icons icon-world",
        component: RefundView,
        layout: "/admin"
    },
    // withdraw router - connect with withdraw create page
    {
        path: "/withdrawCreate",
        name: "출금내역 등록",
        rtlName: "출금내역 등록",
        icon: "tim-icons icon-world",
        component: WithdrawCreate,
        layout: "/admin"
    },
    // withdraw router - connect with withdraw list page
    {
        path: "/withdrawList",
        name: "출금내역",
        rtlName: "출금내역",
        icon: "tim-icons icon-world",
        component: WithdrawList,
        layout: "/admin"
    },
    // withdraw router - connect with withdraw create page
     {
        path: "/withdrawView",
        name: "출금상세",
        rtlName: "출금상세",
        icon: "tim-icons icon-world",
        component: WithdrawView,
        layout: "/admin"
    },
    // platform router - connect with platform create page
    {
        path: "/platformCreate",
        name: "수익플랫폼 등록",
        rtlName: "수익플랫폼 등록",
        icon: "tim-icons icon-world",
        component: PlatformCreate,
        layout: "/admin"
    },
    // platform router - connect with platform list page
    {
        path: "/platformList",
        name: "수익플랫폼 리스트",
        rtlName: "수익플랫폼 리스트",
        icon: "tim-icons icon-world",
        component: PlatformList,
        layout: "/admin"
    },
    // platform router - connect with platform create page
     {
        path: "/platformView",
        name: "수익플랫폼 상세",
        rtlName: "수익플랫폼 상세",
        icon: "tim-icons icon-world",
        component: PlatformView,
        layout: "/admin"
    },
    // budget router - connect with budget create page
    {
        path: "/budgetCreate",
        name: "월별 플랫폼 수익 등록",
        rtlName: "월별 플랫폼 수익 등록",
        icon: "tim-icons icon-world",
        component: BudgetCreate,
        layout: "/admin"
    },
    // budget router - connect with budget list page
    {
        path: "/budgetList",
        name: "월별 플랫폼 수익 리스트",
        rtlName: "월별 플랫폼 수익 리스트",
        icon: "tim-icons icon-world",
        component: BudgetList,
        layout: "/admin"
    },
    // budget router - connect with budget create page
     {
        path: "/budgetView",
        name: "월별 플랫폼 수익 상세",
        rtlName: "월별 플랫폼 수익 상세",
        icon: "tim-icons icon-world",
        component: BudgetView,
        layout: "/admin"
    },
    // ticket router - connect with funding list page
    {
        path: "/ticketList",
        name: "티켓리스트",
        rtlName: "티켓리스트",
        icon: "tim-icons icon-world",
        component: TicketList,
        layout: "/admin"
    },
    // ticket router - connect with funding create page
     {
        path: "/ticketCreate",
        name: "티켓등록",
        rtlName: "create funding",
        icon: "tim-icons icon-world",
        component: TicketCreate,
        layout: "/admin"
    },
    // dao project router - connect with dao project list page
    {
        path: "/dao/projectList",
        name: "DAO 프로젝트",
        rtlName: "DAO 프로젝트",
        icon: "tim-icons icon-world",
        component: DaoProjectList,
        layout: "/admin"
    },
    // dao project router - connect with dao project create page
    {
        path: "/dao/projectCreate",
        name: "DAO 프로젝트 등록",
        rtlName: "DAO 프로젝트 등록",
        icon: "tim-icons icon-world",
        component: DaoProjectCreate,
        layout: "/admin"
    },
    // dao project router - connect with dao project list page
    {
        path: "/dao/noticeList",
        name: "DAO 공지",
        rtlName: "DAO 공지",
        icon: "tim-icons icon-world",
        component: DaoNoticeList,
        layout: "/admin"
    },
    // dao project router - connect with dao project list page
    {
        path: "/dao/noticeCreate",
        name: "DAO 공지 등록",
        rtlName: "DAO 공지 등록",
        icon: "tim-icons icon-world",
        component: DaoNoticeCreate,
        layout: "/admin"
    },
    // dao project router - connect with dao project list page
    {
        path: "/dao/ProjectNoticeList",
        name: "DAO 프로젝트 공지",
        rtlName: "DAO 프로젝트 공지",
        icon: "tim-icons icon-world",
        component: DaoProjectNoticeList,
        layout: "/admin"
    },
    // dao project router - connect with dao project list page
    {
        path: "/dao/ProjectNoticeCreate",
        name: "DAO 프로젝트 공지 등록",
        rtlName: "DAO 프로젝트 공지 등록",
        icon: "tim-icons icon-world",
        component: DaoProjectNoticeCreate,
        layout: "/admin"
    },
    
];
export default routes;
