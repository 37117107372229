/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* create notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// control session
import Session from "module/session/Session";
// module platform
import Platform from "module/platform/Platform";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

function PlatformCreate() {

  const [data, setData] = useState([]);
  const [name, setName] = useState('');
  const [shareRate, setShareRate] = useState(0);

  const queryParameters = new URLSearchParams(window.location.search)
  const platformId = queryParameters.get("platformId") ?? ''

  const sessionInfo = Session.getSession();


  //platform class
  let platform = new Platform(process.env.REACT_APP_TICKETAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });


  useEffect(() => {
    if (platformId != '' && platformId != null) {
      getData();
    }
  }, [])

  const getData = async () => {
    var result = await platform.findOne('platformId=' + platformId)
    if(result.data.data){
      setName(result.data.data.name);
      setShareRate(result.data.data.shareRate);
    }
  }

  const handleChange = async (e) => {
    if (e.target.name == 'name') {
        setName(e.target.value)
    } else if (e.target.name == 'shareRate') {
       setShareRate(e.target.value)
    }
  };


  //등록 혹은 수정
  const Submit = async () => {

    // 플랫폼명
    var name = document.getElementById("name").value;
    // 분배율
    var shareRate = document.getElementById("shareRate").value;
    
    var data = {
      name: name,
      shareRate: shareRate
    };


    if (platformId && platformId != '' && platformId != null) {
      data.platformId = platformId
    }

    const result = platformId != '' && platformId != null ? await platform.update(data) : await platform.create(data);

    if (result.data.code == 200) {
      window.location.href = '/admin/platformList';
    } else {
      alert('등록실패');
    }

  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12" lg="12">
            <Card>
              <CardHeader>
                {platformId != '' && platformId != null ? <h3 className="Category">수익플랫폼 수정</h3> : <h3 className="Category">수익플랫폼 등록</h3>}
              </CardHeader>
              <CardBody>
                <Form>
                <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>플랫폼명</label>
                      <Input
                        name="name"
                        defaultValue={name ?? ''}
                        placeholder="플랫폼명을 입력해 주세요."
                        type="text"
                        className="fs-5"
                        id="name"
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>분배율(밈비가 수익금 가져가는 비율[100% = 1.50% = 0.5])</label>
                      <Input
                        name="shareRate"
                        defaultValue={shareRate ?? 0}
                        placeholder="밈비가 수익금 가져가는 비율을 입력해 주세요."
                        type="text"
                        className="fs-5"
                        id="shareRate"
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="button" onClick={() => { Submit() }} >
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PlatformCreate;
